import create from 'zustand';
import { devtools } from 'zustand/middleware';

const TOOL_DATA = {
  edgeSnapping: false,
  vertexSnapping: false
};

const reducer = (state: $TSFixMe, { type, payload }: $TSFixMe) => {
  switch (type) {
    case 'SET_TOOL_SNAPPING':
      return { ...state, [payload.type]: payload.value };
    default:
      return state;
  }
};

const store = (set: $TSFixMe) => ({
  ...TOOL_DATA,
  dispatch: (args: $TSFixMe) => set((state: $TSFixMe) => reducer(state, args))
});

export const useToolData = create(devtools(store));
