import { BLUEPRINT_REQUEST_STATUS_ENUM, REQUEST_STATUS_ENUM, TOOLS_ID } from '../../../Constants/Constant';
import AddLabelIcon from '../../../Icons/add-label.svg';
import AddCurveIcon from '../../../Icons/add-curve.svg';
import EditParcelIcon from '../../../Icons/edit-parcel.svg';
import EditFeatureIcon from '../../../Icons/edit-feature.svg';
import MoveFeatureIcon from '../../../Icons/move-tool.svg';
import AddParcelIcon from '../../../Icons/draw-lot.svg';
import AddPolygonIcon from '../../../Icons/add-feature.svg';
import DeleteParcelIcon from '../../../Icons/delete.svg';
import TagsIcon from '../../../Icons/tags-icon.svg';
import CutIcon from '../../../Icons/cut-tool.svg';
import CopyIcon from '../../../Icons/copy-tool.svg';
import PasteIcon from '../../../Icons/paste-tool.svg';
import CircleHoleIcon from '../../../Icons/draw-hole.svg';
import PolygonDrawHole from '../../../Icons/polygon-cuthole.svg';
import SplitIcon from '../../../Icons/split.svg';
import ReclassifyIcon from '../../../Icons/reclassify.svg';
import AddNearbyIcon from '../../../Icons/nearby.svg';
import RotateClockWiseIcon from '../../../Icons/rotate-clockwise-tool.svg';
import RotateCounterClockWiseIcon from '../../../Icons/rotate-counterclockwise-tool.svg';
import FlipHorizontalIcon from '../../../Icons/flip-horizontal-tool.svg';
import FlipVerticalIcon from '../../../Icons/flip-vertical-tool.svg';
import SelectIcon from '../../../Icons/select-tool.svg';
import LassoIcon from '../../../Icons/lasso-select-tool.svg';
import MeasureIcon from '../../../Icons/measure.svg';
import UploadImageIcon from '../../../Icons/add-icon-tool.svg';
import ArrowIcon from '../../../Icons/arrow.svg';
import AddLabelBoxIcon from '../../../Icons/label-box.svg';
import NoteIcon from '../../../Icons/note.svg';
import AddRectangleIcon from '../../../Icons/add-rect-tool.svg';
import AddCircleIcon from '../../../Icons/add-circle.svg';
import FreeHandIcon from '../../../Icons/freehand-tool.svg';
import EditLayer from '../../OlMap/Tools/EditLayer';
import ReClassifier from '../../OlMap/Tools/ReClassifier';
import LassoReclassify from '../../OlMap/Tools/LassoReclassify';
import AddFeatures from '../../OlMap/Tools/AddFeatures';
import SplitFeature from '../../OlMap/Tools/SplitFeature';
import Labels from '../../OlMap/Tools/Labels';
import DrawHoleTool from '../../OlMap/Tools/DrawHole';
import DeleteFeature from '../../OlMap/Tools/DeleteFeature';
import NearbyParcels from '../../OlMap/Tools/NearbyParcels';
import EditParcel from '../../OlMap/Tools/EditParcel';
import MeasureTool from '../../OlMap/Tools/MeasureTool';
import ArrowTool from '../../OlMap/Tools/ArrowTool';
import LabelBoxTool from '../../OlMap/Tools/LabelBox';
import UploadIcon from '../../OlMap/Tools/UploadIcon';
import Notes from '../../OlMap/Tools/Notes';
import ScaleLine from '../../OlMap/Tools/ScaleLine';
import SelectTool from '../../OlMap/Tools/SelectTool';
import RotateTool from '../../OlMap/Tools/RotateTool';
import FlipTool from '../../OlMap/Tools/FlipTool';
import MoveFeature from '../../OlMap/Tools/MoveFeature';
import LoadClipboard from '../../OlMap/Tools/LoadClipboard';
import PasteFeatures from '../../OlMap/Tools/PasteFeatures';
import ZoneMeasurement from '../../OlMap/Tools/ZoneMeasurement';
import CircularHole from '../../OlMap/Tools/CircularHole';
import FillTool from '../../OlMap/Tools/FillTool';
import FillToolIcon from '../../../Icons/fill-tool.svg';
import LassoSelect from '../../OlMap/Tools/LassoSelect';
import ZoneMerge from '../../OlMap/Tools/ZoneMerge';
import DragRotate from '../../OlMap/Tools/DragRotate';
import DragRotateIcon from '../../../Icons/rotate_tool.svg';
import SnapToolIcon from '../../../Icons/snap-tool.svg';
import AddSnapping from '../../OlMap/Tools/AddSnapping';
import MergeFeatures from '../../OlMap/Tools/MergeFeatures';
import MergeToolIcon from '../../../Icons/merge-tool.svg';

export const TOOLBAR_MAP = [
  TOOLS_ID.ADD_PARCEL,
  TOOLS_ID.EDIT_PARCEL,
  TOOLS_ID.NEARBY_PARCEL,
  TOOLS_ID.ADD_FEATURE,
  TOOLS_ID.ADD_RECTANGLE,
  TOOLS_ID.ADD_CIRCLE,
  TOOLS_ID.ADD_CURVE,
  TOOLS_ID.FREE_HAND,
  TOOLS_ID.FILL_TOOL,
  TOOLS_ID.EDIT_POLYGON,
  TOOLS_ID.MOVE_FEATURE,
  TOOLS_ID.ROTATE_TOOL,
  TOOLS_ID.ROTATE_CLOCKWISE,
  TOOLS_ID.ROTATE_COUNTER_CLOCKWISE,
  TOOLS_ID.FLIP_HORIZONTAL,
  TOOLS_ID.FLIP_VERTICAL,
  TOOLS_ID.SPLIT,
  TOOLS_ID.DRAW_HOLE,
  TOOLS_ID.CIRCLE_HOLE,
  TOOLS_ID.SELECT_TOOL,
  TOOLS_ID.LASSO_RECLASSIFY,
  TOOLS_ID.RECLASSIFIER,
  TOOLS_ID.TAG_TOOL,
  TOOLS_ID.LASSO_TAG,
  TOOLS_ID.MEASURE_TOOL,
  TOOLS_ID.LABEL,
  TOOLS_ID.LABEL_BOX,
  TOOLS_ID.CUT_TOOL,
  TOOLS_ID.COPY_TOOL,
  TOOLS_ID.PASTE_TOOL,
  TOOLS_ID.DELETE_FEATURE,
  TOOLS_ID.NOTES_TOOL,
  TOOLS_ID.SCALE_LINE_TOOL,
  TOOLS_ID.ADD_ARROW,
  TOOLS_ID.UPLOAD_ICON,
  TOOLS_ID.DELETE_PARCEL,
  TOOLS_ID.ZONE_DIVISION_TOOL,
  TOOLS_ID.ZONE_MERGE_TOOL,
  TOOLS_ID.MERGE_TOOL
];

export const DRAFT_TOOLS = [
  TOOLS_ID.ADD_PARCEL,
  TOOLS_ID.EDIT_PARCEL,
  TOOLS_ID.NEARBY_PARCEL,
  TOOLS_ID.DRAW_HOLE,
  TOOLS_ID.DELETE_PARCEL
];

export const TOOLS = {
  [TOOLS_ID.ADD_FEATURE]: {
    class: AddFeatures,
    label: 'add-feature',
    title: 'Add Feature',
    icon: AddPolygonIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Add Feature Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'A' },
    subTools: [
      {
        class: AddFeatures,
        toolId: TOOLS_ID.ADD_FEATURE,
        label: 'add-feature',
        title: 'Add Feature',
        icon: AddPolygonIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Feature Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'A' }
      },
      {
        class: AddFeatures,
        toolId: TOOLS_ID.FREE_HAND,
        label: 'free-hand',
        title: 'Free Hand',
        icon: FreeHandIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Free Hand Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'F' }
      },
      {
        class: AddFeatures,
        toolId: TOOLS_ID.ADD_RECTANGLE,
        label: 'add-rectangle',
        title: 'Add Rectangle',
        icon: AddRectangleIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Rectangle',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'B' }
      },
      {
        class: AddFeatures,
        toolId: TOOLS_ID.ADD_CIRCLE,
        label: 'add-circle',
        title: 'Add Circle',
        icon: AddCircleIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Circle',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'O' }
      },
      {
        class: AddFeatures,
        toolId: TOOLS_ID.ADD_CURVE,
        label: 'add-curve',
        title: 'Add Curve',
        icon: AddCurveIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Curve',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'Y' }
      },
      {
        class: FillTool,
        toolId: TOOLS_ID.FILL_TOOL,
        label: 'fill-tool',
        title: 'Fill Tool',
        icon: FillToolIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Fill Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'Q' }
      }
    ]
  },
  [TOOLS_ID.ADD_RECTANGLE]: {
    class: AddFeatures,
    label: 'add-rectangle',
    title: 'Add Rectangle',
    icon: AddRectangleIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Add Rectangle',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'B' }
  },
  [TOOLS_ID.ADD_CIRCLE]: {
    class: AddFeatures,
    label: 'add-circle',
    title: 'Add Circle',
    icon: AddCircleIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Add Circle',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'O' }
  },
  [TOOLS_ID.FREE_HAND]: {
    class: AddFeatures,
    label: 'free-hand',
    title: 'Free Hand',
    icon: FreeHandIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Free Hand Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'F' }
  },
  [TOOLS_ID.ADD_CURVE]: {
    class: AddFeatures,
    toolId: TOOLS_ID.ADD_CURVE,
    label: 'add-curve',
    title: 'Add Curve',
    icon: AddCurveIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '15',
    width: '15',
    name: 'Add Curve',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'Y' }
  },
  [TOOLS_ID.FILL_TOOL]: {
    class: FillTool,
    toolId: TOOLS_ID.FILL_TOOL,
    label: 'fill-tool',
    title: 'Fill Tool',
    icon: FillToolIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '15',
    width: '15',
    name: 'Fill Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'Q' }
  },
  [TOOLS_ID.EDIT_POLYGON]: {
    class: EditLayer,
    label: 'edit-polygon',
    title: 'Edit',
    icon: EditFeatureIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Edit Feature Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'E' },
    subTools: [
      {
        class: EditLayer,
        toolId: TOOLS_ID.EDIT_POLYGON,
        label: 'edit-polygon',
        title: 'Edit',
        icon: EditFeatureIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Edit Feature Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'E' }
      },
      {
        class: MoveFeature,
        toolId: TOOLS_ID.MOVE_FEATURE,
        label: 'move-feature',
        title: 'Move',
        icon: MoveFeatureIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Move Feature Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'M' }
      }
    ]
  },
  [TOOLS_ID.MOVE_FEATURE]: {
    class: MoveFeature,
    label: 'move-feature',
    title: 'Move',
    icon: MoveFeatureIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Move Feature Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'M' }
  },
  [TOOLS_ID.ROTATE_TOOL]: {
    class: DragRotate,
    label: 'drag-rotate',
    title: 'Rotate',
    icon: DragRotateIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Rotate Right Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'R' },
    subTools: [
      {
        class: DragRotate,
        toolId: TOOLS_ID.ROTATE_TOOL,
        label: 'drag-rotate',
        title: 'Rotate',
        icon: DragRotateIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '19',
        width: '19',
        name: 'Rotate Right Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'R' }
      },
      {
        class: RotateTool,
        toolId: TOOLS_ID.ROTATE_CLOCKWISE,
        label: 'rotate-right',
        title: 'Rotate Right',
        icon: RotateClockWiseIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Rotate Right Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: '→' }
      },
      {
        class: RotateTool,
        toolId: TOOLS_ID.ROTATE_COUNTER_CLOCKWISE,
        label: 'rotate-left',
        title: 'Rotate Left',
        icon: RotateCounterClockWiseIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Rotate Left Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: '←' }
      },
      {
        class: FlipTool,
        toolId: TOOLS_ID.FLIP_HORIZONTAL,
        label: 'flip-horizontal',
        title: 'Flip Horizontal',
        icon: FlipHorizontalIcon,
        visibleStatus: [],
        bpVisibleStatus: [],
        height: '15',
        width: '15',
        name: 'Flip Horizontal Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'H' }
      },
      {
        class: FlipTool,
        toolId: TOOLS_ID.FLIP_VERTICAL,
        label: 'flip-vertical',
        title: 'Flip Vertical',
        icon: FlipVerticalIcon,
        visibleStatus: [],
        bpVisibleStatus: [],
        height: '15',
        width: '15',
        name: 'Flip Vertical Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'V' }
      }
    ]
  },
  [TOOLS_ID.ROTATE_CLOCKWISE]: {
    class: RotateTool,
    label: 'rotate-right',
    title: 'Rotate Right',
    icon: RotateClockWiseIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Rotate Right Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: '→' }
  },
  [TOOLS_ID.ROTATE_COUNTER_CLOCKWISE]: {
    class: RotateTool,
    label: 'rotate-left',
    title: 'Rotate Left',
    icon: RotateCounterClockWiseIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Rotate Left Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: '←' }
  },
  [TOOLS_ID.FLIP_HORIZONTAL]: {
    class: FlipTool,
    label: 'flip-horizontal',
    title: 'Flip Horizontal',
    icon: FlipHorizontalIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Flip Horizontal Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'H' }
  },
  [TOOLS_ID.FLIP_VERTICAL]: {
    class: FlipTool,
    label: 'flip-vertical',
    title: 'Flip Vertical',
    icon: FlipVerticalIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Flip Vertical Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'V' }
  },
  [TOOLS_ID.SPLIT]: {
    class: SplitFeature,
    label: 'split-feature',
    title: 'Split',
    icon: SplitIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Split Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'P' }
  },
  [TOOLS_ID.DRAW_HOLE]: {
    class: DrawHoleTool,
    label: 'draw-hole',
    title: 'Cut Hole',
    icon: PolygonDrawHole,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED, REQUEST_STATUS_ENUM.DRAFT],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Cut Hole Tool',
    borderRight: true,
    toolTip: { winKey: 'Ctrl', macKey: '⌥', letter: 'C' },
    subTools: [
      {
        class: DrawHoleTool,
        toolId: TOOLS_ID.DRAW_HOLE,
        label: 'draw-hole',
        title: 'Cut Hole',
        icon: PolygonDrawHole,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED, REQUEST_STATUS_ENUM.DRAFT],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '19',
        width: '19',
        name: 'Cut Hole Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'K' }
      },
      {
        class: CircularHole,
        toolId: TOOLS_ID.CIRCLE_HOLE,
        label: 'draw-hole',
        title: 'Circle Hole',
        icon: CircleHoleIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '19',
        width: '19',
        name: 'Circle Hole Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'X' }
      }
    ]
  },
  [TOOLS_ID.CIRCLE_HOLE]: {
    class: CircularHole,
    toolId: TOOLS_ID.CIRCLE_HOLE,
    label: 'draw-hole',
    title: 'Circle Hole',
    icon: CircleHoleIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Circle Hole Tool',
    borderRight: true,
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'X' }
  },
  [TOOLS_ID.SELECT_TOOL]: {
    class: SelectTool,
    label: 'select-tool',
    title: 'Select',
    icon: SelectIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Select Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'S' },

    subTools: [
      {
        class: SelectTool,
        toolId: TOOLS_ID.SELECT_TOOL,
        label: 'select-tool',
        title: 'Select',
        icon: SelectIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Select Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'S' }
      },
      {
        class: LassoReclassify,
        toolId: TOOLS_ID.LASSO_RECLASSIFY,
        label: 'lasso-reclassify-tool',
        title: 'Lasso',
        icon: LassoIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Lasso Reclassify Tool',
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'L' }
      }
    ]
  },
  [TOOLS_ID.TAG_TOOL]: {
    class: SelectTool,
    label: 'tag-tool',
    title: 'Tags',
    icon: TagsIcon,
    visibleStatus: [],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Tag Tool',
    borderRight: true,
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'T' },

    subTools: [
      {
        class: SelectTool,
        toolId: TOOLS_ID.TAG_TOOL,
        label: 'tag-tool',
        title: 'Tags',
        icon: TagsIcon,
        visibleStatus: [],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Tag Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'T' }
      },
      {
        class: LassoSelect,
        toolId: TOOLS_ID.LASSO_TAG,
        label: 'lasso-tag-tool',
        title: 'Lasso Tags',
        icon: TagsIcon,
        visibleStatus: [],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Lasso Tag Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'G' }
      }
    ]
  },

  [TOOLS_ID.LASSO_TAG]: {
    class: LassoSelect,
    label: 'lasso-tag-tool',
    title: 'Lasso Tags',
    icon: SelectIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Lasso Tag Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'G' }
  },

  [TOOLS_ID.RECLASSIFIER]: {
    class: ReClassifier,
    label: 're-classifier',
    title: 'Reclassify',
    icon: ReclassifyIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Reclassifier Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'C' }
  },
  [TOOLS_ID.LASSO_RECLASSIFY]: {
    class: LassoReclassify,
    label: 'lasso-reclassify-tool',
    title: 'Lasso',
    icon: LassoIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Lasso Reclassify Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'L' }
  },
  [TOOLS_ID.LABEL]: {
    class: Labels,
    label: 'edit-label',
    title: 'Label',
    icon: AddLabelIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '15',
    width: '15',
    name: 'Add Label Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'J' },
    subTools: [
      {
        class: Labels,
        toolId: TOOLS_ID.LABEL,
        label: 'edit-label',
        title: 'Label',
        icon: AddLabelIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Label Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'J' }
      },
      {
        class: LabelBoxTool,
        toolId: TOOLS_ID.LABEL_BOX,
        label: 'edit-label-box',
        title: 'Label Box',
        bptTitle: 'Text Box',
        icon: AddLabelBoxIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Label Box Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: '/' }
      },
      {
        class: ArrowTool,
        toolId: TOOLS_ID.ADD_ARROW,
        label: 'arrow-tool',
        title: 'Arrow',
        icon: ArrowIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Add Arrow Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: '>' }
      },
      {
        class: UploadIcon,
        toolId: TOOLS_ID.UPLOAD_ICON,
        label: 'upload-icon-tool',
        title: 'Icon',
        icon: UploadImageIcon,
        visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
        bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
        height: '15',
        width: '15',
        name: 'Upload Icon Tool',
        borderRight: true,
        toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'I' }
      }
    ]
  },
  [TOOLS_ID.LABEL_BOX]: {
    class: LabelBoxTool,
    label: 'edit-label-box',
    title: 'Label Box',
    bptTitle: 'Text Box',
    icon: AddLabelBoxIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '15',
    width: '15',
    name: 'Add Label Box Tool',
    borderRight: true,
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: '/' }
  },
  [TOOLS_ID.DELETE_FEATURE]: {
    class: DeleteFeature,
    label: 'delete-feature',
    title: 'Delete',
    icon: DeleteParcelIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Delete Feature Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: '⌫' }
  },
  [TOOLS_ID.CUT_TOOL]: {
    class: LoadClipboard,
    label: 'cut-feature',
    title: 'Cut',
    icon: CutIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Cut Feature Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌘', letter: 'X' }
  },
  [TOOLS_ID.COPY_TOOL]: {
    class: LoadClipboard,
    label: 'copy-feature',
    title: 'Copy',
    icon: CopyIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Copy Feature Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌘', letter: 'C' }
  },
  [TOOLS_ID.PASTE_TOOL]: {
    class: PasteFeatures,
    label: 'paste-feature',
    title: 'Paste',
    icon: PasteIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Paste Feature Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌘', letter: 'V' }
  },
  [TOOLS_ID.NEARBY_PARCEL]: {
    class: NearbyParcels,
    label: 'nearby-parcel',
    title: 'Add Nearby',
    icon: AddNearbyIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.DRAFT],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Add Nearby Properties Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌥', letter: 'N' }
  },
  [TOOLS_ID.ADD_PARCEL]: {
    class: EditParcel,
    label: 'draw-parcel',
    title: 'Add boundary',
    icon: AddParcelIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.DRAFT],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Add Lot Boundary Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌥', letter: 'T' }
  },
  [TOOLS_ID.EDIT_PARCEL]: {
    class: EditParcel,
    label: 'edit-parcel',
    title: 'Edit boundary',
    icon: EditParcelIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.DRAFT],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Edit Lot Boundary Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌥', letter: 'E' }
  },
  [TOOLS_ID.MEASURE_TOOL]: {
    class: MeasureTool,
    label: 'measure-tool',
    title: 'Measure',
    icon: MeasureIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Measure Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'D' }
  },
  [TOOLS_ID.DELETE_PARCEL]: {
    class: DeleteFeature,
    label: 'delete-parcel',
    title: 'Delete',
    icon: DeleteParcelIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.DRAFT],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Delete Lot Boundary Tool',
    toolTip: { winKey: 'Ctrl', macKey: '⌥', letter: 'D' }
  },
  [TOOLS_ID.ADD_ARROW]: {
    class: ArrowTool,
    label: 'arrow-tool',
    title: 'Arrow',
    icon: ArrowIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Add Arrow Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: '>' }
  },
  [TOOLS_ID.UPLOAD_ICON]: {
    class: UploadIcon,
    label: 'upload-icon-tool',
    title: 'Icon',
    icon: UploadImageIcon,
    visibleStatus: [],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    name: 'Upload Icon Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'I' }
  },
  [TOOLS_ID.NOTES_TOOL]: {
    class: Notes,
    label: 'notes-tool',
    icon: NoteIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    title: 'Add notes',
    height: '18',
    width: '18',
    name: 'Notes Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'N' }
  },
  [TOOLS_ID.SCALE_LINE_TOOL]: {
    class: ScaleLine,
    label: 'scale-line-tool',
    visibleStatus: [],
    bpVisibleStatus: [],
    title: 'Scale Line',
    name: 'Scale Line Tool'
  },
  [TOOLS_ID.ZONE_DIVISION_TOOL]: {
    class: ZoneMeasurement,
    label: 'zone-measurement-feature',
    title: 'Zone',
    bpVisibleStatus: [],
    visibleStatus: [],
    name: 'Zone Division Tool'
  },
  [TOOLS_ID.ZONE_MERGE_TOOL]: {
    class: ZoneMerge,
    label: 'zone-merge-tool',
    title: 'Zone Merge',
    bpVisibleStatus: [],
    visibleStatus: [],
    name: 'Zone Merge Tool'
  },
  [TOOLS_ID.ADD_SNAPPING]: {
    class: AddSnapping,
    label: 'snapping-tool',
    title: 'Snap to',
    name: 'Snapping tool',
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [],
    height: '19',
    width: '19',
    icon: SnapToolIcon,
    borderRight: true,
    subTools: []
  },
  [TOOLS_ID.MERGE_TOOL]: {
    class: MergeFeatures,
    label: 'merge-tool',
    title: 'Merge',
    icon: MergeToolIcon,
    visibleStatus: [REQUEST_STATUS_ENUM.COMPLETED],
    bpVisibleStatus: [BLUEPRINT_REQUEST_STATUS_ENUM.COMPLETED],
    height: '19',
    width: '19',
    name: 'Merge Tool',
    toolTip: { winKey: 'Alt', macKey: '⌥', letter: 'W' }
  }
};

export const TOOL_SHORTCUTS: { [key: number]: number } = {
  65: TOOLS_ID.ADD_FEATURE,
  70: TOOLS_ID.FREE_HAND,
  66: TOOLS_ID.ADD_RECTANGLE,
  79: TOOLS_ID.ADD_CIRCLE,
  89: TOOLS_ID.ADD_CURVE,
  81: TOOLS_ID.FILL_TOOL,
  69: TOOLS_ID.EDIT_POLYGON,
  77: TOOLS_ID.MOVE_FEATURE,
  82: TOOLS_ID.ROTATE_TOOL,
  80: TOOLS_ID.SPLIT,
  75: TOOLS_ID.DRAW_HOLE,
  83: TOOLS_ID.SELECT_TOOL,
  67: TOOLS_ID.RECLASSIFIER,
  76: TOOLS_ID.LASSO_RECLASSIFY,
  74: TOOLS_ID.LABEL,
  190: TOOLS_ID.ADD_ARROW,
  73: TOOLS_ID.UPLOAD_ICON,
  8: TOOLS_ID.DELETE_FEATURE,
  88: TOOLS_ID.CIRCLE_HOLE
};

export const DRAFT_TOOL_SHORTCUTS: { [key: number]: number } = {
  84: TOOLS_ID.ADD_PARCEL,
  69: TOOLS_ID.EDIT_PARCEL,
  78: TOOLS_ID.NEARBY_PARCEL,
  67: TOOLS_ID.DRAW_HOLE,
  68: TOOLS_ID.DELETE_PARCEL
};

export const FEATURE_ACTIONS: { [key: number]: { action: number } } = {
  39: { action: TOOLS_ID.ROTATE_CLOCKWISE },
  37: { action: TOOLS_ID.ROTATE_COUNTER_CLOCKWISE },
  72: { action: TOOLS_ID.FLIP_HORIZONTAL },
  86: { action: TOOLS_ID.FLIP_VERTICAL },
  87: { action: TOOLS_ID.MERGE_TOOL }
};
