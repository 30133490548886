import { Snap } from 'ol/interaction';

class AddSnapping {
  mapObj: any;
  snap: Array<Snap>;

  constructor(mapObj: any) {
    this.mapObj = mapObj;
    this.snap = [];
  }

  on(edgeSnapping: boolean = true, vertexSnapping: boolean = true): void {
    this.off();

    this.snap = this.mapObj.getSnap(edgeSnapping, vertexSnapping);
    this.snap.forEach(snap => {
      this.mapObj.map!.addInteraction(snap);
    });
  }

  off(): void {
    const interactions = this.mapObj.map!.getInteractions();
    const snapsToRemove = interactions.getArray().filter((interaction: any) => interaction instanceof Snap);
    snapsToRemove.forEach((snap: Snap) => {
      interactions.remove(snap);
    });
  }
}

export default AddSnapping;
